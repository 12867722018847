import { isVitestEnv } from "./vitest"

function createApplicationDataForVitest (): Backend.Application {
  return {
    Translations: {},
    Localization: {
      'en-GB': {
        datetime: {
          weekdays: {
            short: [
              "Sun",
              "Mon",
              "Tue",
              "Wed",
              "Thu",
              "Fri",
              "Sat"
            ],
            long: [
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday"
            ]
          },
          months: {
            short: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec"
            ],
            long: [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December"
            ]
          },
          amPM: ['AM', 'PM']
        },
        numbers: {}
      }
    } as Backend.Application['Localization'],
    AppName: 'Avvoka',
    TimeZone: 'Europe/London',
    IsDevelopment: true,
    Links: {
      signInHelp: 'https://help.avvoka.com/en/articles/5830454-how-do-i-access-avvoka-if-i-don-t-have-an-account',
      avvokaWebsite: 'https://www.avvoka.com/',
      footerLinks: []
    },
    Lists: {
      Currency: []
    }
  }
}

const applicationData: Backend.Application = isVitestEnv ? createApplicationDataForVitest() : window.application

export function getApplication () {
  return applicationData
}

export function getTranslations () {
  return applicationData.Translations
}

export function getUserTimeZone () {
  return applicationData.TimeZone || 'Europe/London'
}

export function getAppName () {
  return applicationData.AppName || 'Avvoka'
}

export function getLocalization () {
  return applicationData.Localization
}